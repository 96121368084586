export const BASE_URL_AUTH = 'https://api.admin.barikoi.com/api/v2'

// Map Configs
export const MAP = {
    MAP_STYLES: [
        { value: 'barikoi-light', label: 'Light', labelBn: 'লাইট' },
        { value: 'barikoi-dark', label: 'Dark', labelBn: 'ডার্ক' }
    ]
}

// Zone Configs
export const ZONE_AREAS = {
    // GET_ZONE_AREAS_API: 'https://api.bmapsbd.com/api/zone/geoJSON',
    GET_ZONE_AREAS_API: 'https://api.urban.barikoimaps.dev/api/zones',

    DATA_ID: 'zone-areas',
    DATA_LABEL: 'Zones',
    STROKE_COLOR: [ 120, 120, 120 ],
    TOOLTIP_FIELDS: [
        {
            name: 'zone_number',
            format: null
        },
        {
            name: 'city_corp',
            format: null
        },
        {
            name: 'target_achieved',
            format: null
        }
    ],
    FILTER_KEY: 'zone_number',
    COLOR_KEY: 'target_achieved_status',
    COLOR_PALETTE: [
        '#ff0000',
        '#ffff00',
        '#00ff00'
    ]
}

// Ward Configs
export const WARD_POINTS = {
    GET_WARD_POINTS_API: `${BASE_URL_AUTH}/ward/places/`,
    GET_WARD1_17_POINTS_API: 'https://api.urban.barikoimaps.dev/api/holding_zone',
    GET_DASHBOARD_DATA_API: 'https://api.urban.barikoimaps.dev/api/holding_zone/statistic',
    GET_DASHBOARD_BARCHART: 'https://api.urban.barikoimaps.dev/api/get_statistics/barchart', // + barchart?from_date=2023-05-01&to_date=2023-05-20
    // GET_WARD1_17_POINTS_API: 'http://192.168.68.165:8003/api/holding_zone',
    //staging
    GET_WARD15_POINTS_API: 'https://barikoi.admin.api.barikoimaps.dev/get-urban-places-by-ucode',
    //production
    // GET_WARD15_POINTS_API: `${BASE_URL_AUTH}/get-urban-places-by-ucode`,
    // GET_HOLDING_DETAILS: 'https://bkoih3.ml/api/holdings',
    GET_HOLDING_DETAILS: null,
    DATA_ID: 'ward-points',
    DATA_LABEL: 'Holdings',
    // COLOR_KEY: 'placeType',
    COLOR_KEY: 'status',

    FILTER_KEY: 'placeType',
    TOOLTIP_FIELDS: [
        // {
        //     name: 'place_name',
        //     format: null
        // },
        // {
        //     name: 'business_name',
        //     format: null
        // },
        {
            name: 'Address',
            format: null
        },
        // {
        //     name: 'pType',
        //     format: null
        // },
        // {
        //     name: 'subType',
        //     format: null
        // },
        {
            name: 'area',
            format: null,
        },
        {
            name: 'owner_name',
            format: null,
        },
        {
            name: 'eholding',
            format: null,
        },
        // {
        //     name: 'postCode',
        //     format: null
        // },
        // {
        //     name: 'thana',
        //     format: null
        // },
        // {
        //     name: 'district',
        //     format: null
        // },
        {
            name: 'nature',
            format: null
        },
        {
            name: 'business_details',
            format: null
        },
        // {
        //     name: 'floor_level_flat',
        //     format: null
        // },
        {
            name: 'flat_no',
            format: null,
        },
        {
            name: 'status',
            format: null
        },
        {
            name: 'currdue',
            format: null,
        },
        {
            name: 'prevdue',
            format: null,
        },
        {
            name: 'qtax',
            format: null,
        } 
    ],
    COLOR_PALETTE: [
        '#7aa457',
        '#dc143c',
        '#a46cb7',
    ]
}

// Ward Area Configs
export const WARD_AREAS = {
    // GET_WARD_AREAS_API: 'https://api.bmapsbd.com/api/ward/geoJSON',
    GET_WARD_AREAS_API: 'https://api.urban.barikoimaps.dev/api/wards',

    DATA_ID: 'ward-areas',
    DATA_LABEL: 'Wards',
    TOOLTIP_FIELDS: [
        {
            name: 'ward_number',
            format: null
        },
        {
            name: 'zone_number',
            format: null
        }
    ],
    FILTER_KEY: 'ward_number',
    COLOR_KEY: 'ward_number',
    STROKE_COLOR: [ 34, 139, 34 ],
    COLOR_PALETTE: [
        '#c78bb9',
        '#5aca5d',
        '#bf5cd3',
        '#8dbd3a',
        '#905fd8',
        '#4ba033',
        '#d84cb2',
        '#45c27f',
        '#ce307b',
        '#b6b731',
        '#5c75e3',
        '#ddaf37',
        '#5b4ba8',
        '#e1892a',
        '#4f77bb',
        '#d94d2b',
        '#45c7c8',
        '#d7314d',
        '#6ebf92',
        '#e63e77',
        '#438c4f',
        '#9a4ca4',
        '#5f8529',
        '#e75e9d',
        '#31947c',
        '#ab367d',
        '#9bb76d',
        '#bd94e4',
        '#a88a27',
        '#7e67ae',
        '#bcb35a',
        '#df80c3',
        '#636b16',
        '#64a7dd',
        '#ab3e26',
        '#2d6a41',
        '#de6262',
        '#5f6e34',
        '#e97e99',
        '#978c4d',
        '#975181',
        '#daa36a',
        '#a5354a',
        '#866527',
        '#bc5371',
        '#ae6529',
        '#91495a',
        '#ea835e',
        '#a25c42',
        '#e08d8b'
    ]
}


// AUTH
export const AUTH = {
    LOGIN_API: `${ BASE_URL_AUTH }/login`,
    GET_USER_API: `${ BASE_URL_AUTH }/auth/user`
}